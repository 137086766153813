<template>
  <div>
    <div class="school hidden-xs-only">
      <el-carousel height="840px" v-if="banners.length > 0">
        <el-carousel-item v-for="item in banners" :key="item.id">
          <el-image class="banner" :src="item.img" alt="" fit="cover" />
        </el-carousel-item>
      </el-carousel>

      <div class="box">
        <div class="container">
          <h1 class="title">校园招聘流程</h1>
          <ul class="flex">
            <li
              class="flex row-center"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="flex column row-center">
                <img :src="item.ico" width="88" height="88" alt="" />
                <p class="title-1">{{ item.content }}</p>
                <p class="date">{{ item.content_two }}</p>
              </div>
              <img
                v-if="index < list.length - 1"
                class="img-7"
                src="@/assets/images/img-7.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="box-1">
        <div class="container">
          <h1 class="title">校招精彩瞬间</h1>
          <el-carousel
            class="carousel"
            arrow="always"
            :interval="2000"
            type="card"
            height="544px"
          >
            <el-carousel-item v-for="item in banners1" :key="item.id">
              <el-image class="image" :src="item.img" fit="cover" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="container">
        <div class="box-2" @click="goRecruit">
          <!-- <img class="img-8" src="@/assets/images/img-8.png" alt="" /> -->
          <div>马上投递></div>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-up">
      <van-nav-bar
        title="校园招聘"
        left-arrow
        fixed
        placeholder
        z-index="99"
        @click-left="back"
      />
      <el-carousel height="181px">
        <el-carousel-item v-for="item in banners" :key="item.id">
          <el-image class="banner" :src="item.img" alt="" fit="cover" />
        </el-carousel-item>
      </el-carousel>
      <van-divider> <h1 class="title">招聘流程</h1></van-divider>

      <ul class="">
        <li class="item-1" v-for="(item, index) in list" :key="index">
          <div class="flex column row-center">
            <img :src="item.ico" width="88" height="88" alt="" />
            <p class="title-1">{{ item.content }}</p>
            <p class="date">{{ item.content_two }}</p>
          </div>
          <img
            v-if="index < list.length - 1"
            class="img-7"
            src="@/assets/images/img-7.png"
            alt=""
          />
        </li>
      </ul>
      <van-divider> <h1 class="title">校招精彩瞬间</h1></van-divider>
      <div class="m-15">
        <el-carousel
          class="carousel"
          arrow="always"
          :interval="2000"
          type="card"
          height="120px"
        >
          <el-carousel-item v-for="item in banners1" :key="item.id">
            <el-image class="image" :src="item.img" fit="cover" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="box-3" @click="goRecruit">
        <img class="img-8" src="@/assets/images/img-8.png" alt="" />
        <div>马上投递></div>
      </div>
    </div>
  </div>
</template>

<script>
import { images, order } from "@/assets/js/api";
export default {
  data() {
    return {
      list: [],
      banners: [],
      banners1: [],
    };
  },
  created() {
    this.getImage();
    this.getOrder();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    goRecruit() {
      this.$router.push("/school-recruit");
    },
    getOrder() {
      order().then(
        (res) => {
          this.list = res.data;
        },
        (err) => {
          console.log(err, "this.list");
        }
      );
    },
    async getImage() {
      let one = images({
        type_id: 1,
      });
      let two = images({
        type_id: 2,
      });
      Promise.all([one, two]).then((values) => {
        this.banners = values[0].data;
        this.banners1 = values[1].data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-3 {
  margin: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: -10px -10px 38px 0 rgba(159, 19, 16, 0.05);
  .img-8 {
    width: 80px;
    height: 80px;
  }
}
.item-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-7 {
    width: 14px;
    margin-top: 10px;
    transform: rotate(90deg);
  }
}
.img-1 {
  height: 180px;
}
.title {
  color: #333;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
/deep/ .van-nav-bar .van-icon {
  color: #fff;
  font-size: 20px;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/ .van-nav-bar {
  background-color: #cf322e;
}
.school {
  .banner {
    width: 100%;
    height: 840px;
  }

  .box-2 {
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: -10px -10px 38px 0 rgba(159, 19, 16, 0.05);

    .img-8 {
      width: 120px;
      height: 112px;
    }
  }

  .box-1 {
    width: 100%;
    height: 900px;
    background-size: 100% 100%;
    background-image: url("../../assets/images/bg.png");

    .carousel {
      padding-top: 120px;

      .image {
        height: 544px;
        border-radius: 20px;
      }
    }

    .title {
      position: relative;
      padding-top: 100px;
      font-size: 32px;
      font-weight: 400;
      text-align: center;

      // &::after {
      //   position: absolute;
      //   left: 50%;
      //   top: 120%;
      //   transform: translate(-50%, -50%);
      //   content: "";
      //   width: 369px;
      //   height: 2px;
      //   background: #9f1310;
      // }

      // &::before {
      //   position: absolute;
      //   left: 50%;
      //   top: 120%;
      //   transform: translate(-50%, -50%);
      //   content: "";
      //   width: 173px;
      //   height: 4px;
      //   background: #9f1310;
      // }
    }
  }

  .box {
    height: 550px;
    background: #f5f5f5;
    border-radius: 8px;
    box-sizing: border-box;

    ul {
      padding-top: 120px;

      li {
        .title-1 {
          margin-top: 20px;
        }

        .date {
          margin-top: 8px;
        }
      }

      .img-7 {
        width: 22px;
        height: 38px;
        margin-left: 50px;
        margin-right: 50px;
      }
    }

    .title {
      position: relative;
      padding-top: 100px;
      font-size: 32px;
      font-weight: 400;
      text-align: center;

      // &::after {
      //   position: absolute;
      //   left: 50%;
      //   top: 120%;
      //   transform: translate(-50%, -50%);
      //   content: "";
      //   width: 369px;
      //   height: 2px;
      //   background: #9f1310;
      // }

      // &::before {
      //   position: absolute;
      //   left: 50%;
      //   top: 120%;
      //   transform: translate(-50%, -50%);
      //   content: "";
      //   width: 173px;
      //   height: 4px;
      //   background: #9f1310;
      // }
    }
  }
}
</style>